(function () {
  angular
    .module("akitabox.ui.dialogs.document.download")
    .factory("DownloadDocumentDialog", DownloadDocumentDialog);

  function DownloadDocumentDialog($mdDialog, $mdMedia, $log, $q) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/document/download/download-file.dialog.html",
      controller: "DownloadDocumentDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    function show(options) {
      if (!options || !options.locals) {
        $log.error("DownloadDocumentDialog: no options local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      } else if (!options.locals.docs) {
        $log.error("DownloadDocumentDialog: no docs local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
