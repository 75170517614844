(function () {
  angular
    .module("akitabox.desktop.schedule.list")
    .controller("ScheduleListController", ScheduleListController);

  /* @ngInject */
  function ScheduleListController(
    // Angular
    $q,
    $location,
    $scope,
    $timeout,
    // Constants
    EVENT_SCHEDULE_CREATE,
    MAINTENANCE_SCHEDULE_STATUS_ACTIVE,
    MAINTENANCE_SCHEDULE_STATUS_CANCELED,
    // Dialogs
    ExportDialog,
    // Services
    ScheduleService,
    MaintenanceTypeService,
    OrganizationService,
    ToastService,
    TradeService,
    FilterBarManager,
    UserService,
    ManagedModelFieldFilter,
    ManagedRoomFilter,
    ManagedAssetFilter,
    ManagedAssigneesFilter,
    ManagedLevelFilter,
    ManagedFilterHelpers,
    // Resolve
    building,
    status
  ) {
    var self = this;

    // Attributes
    self.schedules = null;
    self.organization = OrganizationService.getCurrent();
    self.building = building;
    self.status = status;
    self.subtitle = getSubtitle();
    self.actions = getHeaderActions();
    self.fabActions = getFabActions();
    self.activeFilters = null;

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // apply the fixed ?status=... portion of the query
        query.status = status;
        // update the list
        changeFilters(query);
      },
    });

    var maintenanceTypeConfig;
    var tradeConfig;
    var idConfig;
    var assigneesConfig;

    idConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "ID",
      queryField: "number",
      inputType: "number",
      prefix: "MS-",
      modelValueToChipText: function (inputValue) {
        return "MS-" + inputValue;
      },
    });

    maintenanceTypeConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Maintenance Type",
      queryField: "maintenance_type",
      inputType: "typeahead",
      getEnumOptions: function () {
        return fetchMaintenanceTypes().then(
          ManagedFilterHelpers.mapModelsToEnumOption
        );
      },
      modelValueToFilterValue: ManagedFilterHelpers.modelToId,
      modelValueToChipText: ManagedFilterHelpers.modelToName,
      filterValueToModelValue: function (maintenaceTypeId) {
        return MaintenanceTypeService.get(self.building._id, {
          _id: maintenaceTypeId,
        }).then(function (maintenanceType) {
          return [maintenanceType];
        });
      },
      onAdd: function () {
        if (!self.building.show_maintenance_types) {
          return $q.reject();
        }
      },
    });
    var assetRoomLevelOptions = {
      getBuildingId: function () {
        return self.building._id;
      },
      getOrganization: OrganizationService.getCurrent,
    };

    var assetConfig = new ManagedAssetFilter(
      self.filterBarManager,
      assetRoomLevelOptions
    );
    var roomConfig = new ManagedRoomFilter(
      self.filterBarManager,
      assetRoomLevelOptions
    );
    var levelConfig = new ManagedLevelFilter(
      self.filterBarManager,
      assetRoomLevelOptions
    );

    var subjectConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Subject",
      queryField: "subject",
    });

    var priorityConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Priority",
      queryField: "priority",
      inputType: "typeahead",
      getEnumOptions: function () {
        return ["Low", "Medium", "High", "Emergency"];
      },
      modelValueToFilterValue: function (priority) {
        return priority.toLowerCase();
      },
      modelValueToChipText: function (priority) {
        return priority.charAt(0).toUpperCase() + priority.slice(1);
      },
      filterValueToModelValue: function (queryStringValue) {
        return [queryStringValue];
      },
    });

    tradeConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: " Trade",
      queryField: "trade",
      inputType: "typeahead",
      getEnumOptions: function () {
        return fetchTrades().then(ManagedFilterHelpers.mapModelsToEnumOption);
      },
      modelValueToFilterValue: ManagedFilterHelpers.modelToId,
      modelValueToChipText: ManagedFilterHelpers.modelToName,
      filterValueToModelValue: function (tradeId) {
        return TradeService.getById(self.building._id, tradeId).then(function (
          trade
        ) {
          return [trade];
        });
      },
      onAdd: function () {
        if (!self.building.show_trades) {
          return $q.reject();
        }
      },
    });

    var UNASSIGNED_USER = {
      _id: "null",
      is_deactivated: false,
      identity: {
        _id: "null",
        display_name: "Unassigned",
        email: null,
      },
    };

    assigneesConfig = new ManagedAssigneesFilter(self.filterBarManager, {
      getBuildingId: function () {
        return self.building._id;
      },
      getEnumOptions: function () {
        return fetchAssignees().then(function (assignees) {
          var models = assignees.map(function (user) {
            var identity = user.identity;
            var value =
              identity.display_name !== identity.email
                ? identity.display_name + " | " + identity.email
                : identity.display_name;

            return {
              model: user,
              value: value,
            };
          });

          // Add `Unassigned` as a typeahead option
          models.unshift({
            model: UNASSIGNED_USER,
            value: UNASSIGNED_USER.identity.display_name,
          });

          return models;
        });
      },
      modelValueToFilterValue: function (user) {
        return user._id || user;
      },
      modelValueToChipText: function (user) {
        return user.identity.display_name;
      },
    });

    // hook up the asset, room, and level filters to eachother
    ManagedFilterHelpers.associateAssetRoomLevelFilters({
      room: roomConfig,
      asset: assetConfig,
      level: levelConfig,
    });

    self.filterBarManager.addFilterConfiguration(subjectConfig);
    self.filterBarManager.addFilterConfiguration(idConfig);
    self.filterBarManager.addFilterConfiguration(assigneesConfig);
    self.filterBarManager.addFilterConfiguration(priorityConfig);
    self.filterBarManager.addFilterConfiguration(levelConfig);
    self.filterBarManager.addFilterConfiguration(roomConfig);
    self.filterBarManager.addFilterConfiguration(assetConfig);
    self.filterBarManager.addFilterConfiguration(maintenanceTypeConfig);
    self.filterBarManager.addFilterConfiguration(tradeConfig);

    self.filterInitPromise = self.filterBarManager.applyQuery(
      $location.search()
    );

    // Functions
    self.changeFilters = changeFilters;
    self.fetchSchedules = fetchSchedules;
    self.fetchAllSchedules = fetchAllSchedules;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_SCHEDULE_CREATE, onScheduleCreate);

    // ------------------------
    //   Private Functions
    // ------------------------

    function getSubtitle() {
      var prefix = null;
      var name = "Maintenance Schedules";
      switch (status) {
        case MAINTENANCE_SCHEDULE_STATUS_ACTIVE:
          prefix = "Active";
          break;
        case MAINTENANCE_SCHEDULE_STATUS_CANCELED:
          prefix = "Canceled";
          break;
        default:
      }
      return {
        name: prefix ? prefix + " " + name : name,
      };
    }

    function getHeaderActions() {
      return [
        {
          icon: "get_app",
          text: "Export",
          onClick: exportSchedules,
        },
      ];
    }

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportSchedules,
        },
      ];
    }

    function exportSchedules() {
      var filters = angular.extend({}, { status: status }, self.activeFilters);
      var locals = {
        route: ScheduleService.buildListRoute(building._id),
        filters: filters,
        excelOnly: true,
      };
      ExportDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    function fetchAssignees() {
      return UserService.getAll(self.organization._id, {
        buildings: building._id,
        identity: "$ne,null",
        sort: "firstOrEmail,asc",
        // status: "active", may want to filter on deactivated user
      }).catch(ToastService.showError);
    }

    function fetchMaintenanceTypes(params) {
      return MaintenanceTypeService.getAll(building._id, params).catch(
        ToastService.showError
      );
    }

    function fetchTrades(params) {
      return TradeService.getAll(building._id, params).catch(
        ToastService.showError
      );
    }

    /**
     * Handle schedule creation event
     *
     * @param {Event}       $event      Angular event
     * @param {Schedule[]}  schedules   List of new schedules
     */
    function onScheduleCreate($event, schedules) {
      var addCnt = 0;
      for (var i = 0; i < schedules.length; ++i) {
        if (schedules[i].building._id === self.building._id) {
          if (!self.schedules) {
            self.schedules = [];
          }
          self.schedules.unshift(schedules[i]);
          addCnt++;
        }
      }

      if (addCnt > 0) {
        $timeout(function () {
          $scope.$broadcast("list:refreshClickEvents");
        });
      }
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchSchedules(skip, limit) {
      var doFetch = function () {
        // Add status to url if not preset in active filters
        if (
          self.activeFilters &&
          !Object.prototype.hasOwnProperty.call(self.activeFilters, "status")
        ) {
          $location.search("status", status);
        }

        var params = {
          skip: skip,
          limit: limit,
          status: status,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return ScheduleService.get(building._id, filters)
          .then(function (schedules) {
            if (angular.isArray(self.schedules)) {
              self.schedules = self.schedules.concat(schedules);
            } else {
              self.schedules = schedules;
            }
            return schedules.length;
          })
          .catch(ToastService.showError);
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchAllSchedules(limit) {
      var doFetch = function () {
        // Add status to url if not preset in active filters
        if (
          self.activeFilters &&
          !Object.prototype.hasOwnProperty.call(self.activeFilters, "status")
        ) {
          $location.search("status", status);
        }

        var params = {
          skip: 0,
          limit: limit,
          status: status,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return ScheduleService.getAll(building._id, filters)
          .then(function (schedules) {
            self.schedules = schedules;
            return schedules.length;
          })
          .catch(ToastService.showError);
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.schedules = null;
      $scope.$broadcast("list:refresh");
    }
  }
})();
